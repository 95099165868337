import React, { useState, useEffect } from 'react';
import BattleForm from '../components/BattleForm';
import BattleResult from '../components/BattleResult';
import History from '../components/History';
import { IBattleResult } from '../utils/types';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Confetti from 'react-confetti';
import SadFace from '../components/SadFace';
import Description from "../components/Description";

const Home: React.FC = () => {
    const [battleResult, setBattleResult] = useState<IBattleResult | null>(null);
    const [history, setHistory] = useState<IBattleResult[]>(() => {
        const savedHistory = localStorage.getItem('battleHistory');
        return savedHistory ? JSON.parse(savedHistory) : [];
    });
    const [showConfetti, setShowConfetti] = useState(false);
    const [confettiVisible, setConfettiVisible] = useState(false);

    useEffect(() => {
        if (history.length > 0) {
            const lastResult = history[history.length - 1];
            setBattleResult(lastResult);
        }
    }, []); // Run this effect only once on mount

    useEffect(() => {
        if (battleResult && !history.includes(battleResult)) {
            const newHistory = [...history, battleResult];
            setHistory(newHistory);
            localStorage.setItem('battleHistory', JSON.stringify(newHistory));

            if (battleResult.probability > 0.5) {
                setShowConfetti(true);
                setConfettiVisible(true);
                setTimeout(() => setConfettiVisible(false), 2000);  // Fade out after 2 seconds
                setTimeout(() => setShowConfetti(false), 3000);  // Stop confetti after 3 seconds
            }
        }
    }, [battleResult]); // Removed history from dependency to avoid infinite loop

    const handleBattleComplete = (result: IBattleResult) => {
        setBattleResult(result);
    };

    const handleRestart = () => {
        setBattleResult(null);
        localStorage.removeItem('battleHistory');
        setHistory([]);
    };

    const lastWinner = history.length > 0 ? history[history.length - 1].first : null;
    const usedWords = history.map(h => h.first);

    return (
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column' }}>

            <Box mt={4} sx={{ flex: '1 0 auto' }}>
                {showConfetti && <Confetti recycle={confettiVisible} />}
                {battleResult ? (
                    <>
                        {battleResult.probability <= 0.5 && <SadFace />}
                        <BattleResult
                            result={battleResult}
                            onRestart={handleRestart}
                            onBattleComplete={handleBattleComplete}
                            historyLength={history.length}
                        />
                    </>
                ) : (
                    <>
                        <Box m={1}>
                            <Description/>
                        </Box>
                        <BattleForm
                            onBattleComplete={handleBattleComplete}
                            lastWinner={lastWinner}
                            usedWords={usedWords}
                        />

                    </>
                )}
                {history.length > 0 && (<History history={history} />)}

            </Box>
        </Container>
    );
};

export default Home;
