import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#5C4B7D', // Утонченный темно-фиолетовый
        },
        secondary: {
            main: '#A89EB3', // Мягкий светло-фиолетовый
        },
        background: {
            default: '#F2ECF7', // Очень светлый фиолетовый для фона
            paper: '#E5DEEB', // Чуть более темный для карточек и других элементов
        },
        text: {
            primary: '#4B306A', // Темный фиолетовый для основного текста
            secondary: '#826D9F', // Средний фиолетовый для второстепенного текста
        },
        action: {
            active: '#7A6799', // Мягкий акцент для активных элементов
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
        h1: {
            color: '#4B306A', // Цвет заголовков
        },
        body1: {
            color: '#5C4B7D', // Цвет основного текста
        },
    },
});


export default theme;
