import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './styles/App.css';
import Box from "@mui/material/Box";


const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <Router>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
                <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                    </Routes>
                <Footer
                />
            </Box>
        </Router>
    </ThemeProvider>
);

export default App;
