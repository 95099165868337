import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SadFace: React.FC = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
        <Typography variant="h1" color="error.main">😢</Typography>
    </Box>
);

export default SadFace;
