import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

const Footer: React.FC = () => (
    <Box
        component="footer"
        sx={{
            py: 2,
            px: 2,
            mt: 'auto',
            textAlign: 'center',
            backgroundColor: 'primary.main',
            color: 'white',
        }}
    >
        <Typography variant="body2">
            Разработано Павлиным Николаем - <Link href="https://t.me/pavlin_share" color="inherit">Telegram</Link> | <Link href="https://www.youtube.com/@nikolaypavlin" color="inherit">YouTube</Link> | <Link href="https://letsnova.com" color="inherit">Nova</Link>
        </Typography>
    </Box>
);

export default Footer;
