import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import { motion } from 'framer-motion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    maxWidth: 600,
    margin: 'auto',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[6],
    },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
    '&:last-child': {
        marginBottom: 0,
    },
    '& b': {
        color: theme.palette.primary.main,
    },
}));

const Description: React.FC = () => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <StyledBox onClick={handleToggle}>
                <Typography variant="h5" gutterBottom>
                    Как играть
                </Typography>
                <Collapse in={open}>
                    <StyledTypography variant="body1" paragraph>
                        Ваша цель - <b>ввести слово, которое сможет победить предложенного противника </b>.
                    </StyledTypography>
                    <StyledTypography variant="body1" paragraph>
                        Например, если вам предложили <b>"муха"</b>, вы можете ввести <b>"паук"</b>, который сможет её победить.
                    </StyledTypography>
                    <StyledTypography variant="body1" paragraph>
                        Нажмите <b>"Битва"</b>, чтобы проверить ваше слово. Если вы угадали правильно, вы сможете продолжить игру с введенным вами словом.
                    </StyledTypography>
                </Collapse>
                <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                    <Typography variant="caption">
                        {open ? 'Скрыть описание' : 'Показать описание'}
                    </Typography>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Box>
            </StyledBox>
        </motion.div>
    );
};

export default Description;
