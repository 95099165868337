import axios from 'axios';
import { IBattleResult } from './types';

export const fetchBattleResult = async (first: string, second: string): Promise<IBattleResult> => {
    try {
    const response = await axios.post(
        'https://api.beat.yapa.one/api/v1/vs/',
        { first, second },
        {withCredentials: false},

    );
    return response.data;
    } catch (error) {
        console.error(error);
        return {
            description: 'Возникла ошибка, попробуйте позднее.',
            first,
            emoji_first: '',
            second,
            emoji_second: '',
            guessed_count: 0,
            probability: 0,
        };
    }
};
