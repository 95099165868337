import React, { useState, useEffect } from 'react';
import { fetchBattleResult } from '../utils/api';
import Loader from './Loader';
import { IBattleResult } from '../utils/types';
import { motion } from 'framer-motion';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {nouns} from "../utils/constants";

interface BattleFormProps {
    onBattleComplete: (result: IBattleResult) => void;
    lastWinner: string | null;
    usedWords: string[];
}

const BattleForm: React.FC<BattleFormProps> = ({ onBattleComplete, lastWinner, usedWords }) => {
    const [first, setFirst] = useState('');
    const [second, setSecond] = useState(lastWinner || nouns[Math.floor(Math.random() * nouns.length)]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (lastWinner) {
            setSecond(lastWinner);
        }
    }, [lastWinner]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const usedWordsLowercase = usedWords.map(word => word.toLowerCase());
        if (usedWordsLowercase.includes(first.toLowerCase())) {
            setError('Это слово уже использовалось. Пожалуйста, введите другое слово.');
            return;
        }
        setError('');
        setLoading(true);
        const result = await fetchBattleResult(first, second);
        setLoading(false);
        onBattleComplete(result);
        setFirst('');
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                    Введите слово для битвы против "{second}":
                </Typography>
                <TextField
                    fullWidth
                    label="Ваше слово"
                    variant="outlined"
                    value={first}
                    onChange={(e) => setFirst(e.target.value)}
                    required
                />
                {error && <Typography color="error">{error}</Typography>}
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ mt: 2 }}
                    disabled={loading}
                >
                    Битва!
                </Button>
            </Box>
            {loading && <Loader />}
        </motion.div>
    );
};

export default BattleForm;
