import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { IBattleResult } from '../utils/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { fetchBattleResult } from '../utils/api';

interface BattleResultProps {
    result: IBattleResult;
    onRestart: () => void;
    onBattleComplete: (result: IBattleResult) => void;
    historyLength: number;
}

const BattleResult: React.FC<BattleResultProps> = ({ result, onRestart, onBattleComplete, historyLength }) => {
    const { description, first, emoji_first, second, emoji_second, guessed_count, probability } = result;
    const isWin = probability > 0.5;
    const [nextWord, setNextWord] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const usedWords = JSON.parse(localStorage.getItem('battleHistory') || '[]').map((r: IBattleResult) => r.first);

    const handleNextSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const usedWordsLowercase = usedWords.map((word: string) => word.toLowerCase());
        if (usedWordsLowercase.includes(nextWord.toLowerCase())) {
            setError('Это слово уже использовалось. Пожалуйста, введите другое слово.');
            return;
        }
        setError('');
        setLoading(true);
        const newResult = await fetchBattleResult(nextWord, first);
        setLoading(false);
        onBattleComplete(newResult);
        setNextWord('');
    };

    const history = JSON.parse(localStorage.getItem('battleHistory') || '[]')

    const shareText = `Я провел ${history.length} успешные битвы, но подумал, что ${first} победит ${second}. Сможешь побить мой рекорд?\nhttps://beat.yapa.one\nВот вся история моих битв:\n${history.map((r: IBattleResult) => `${r.first} ${r.emoji_first} vs ${r.second} ${r.emoji_second} - ${r.probability > 0.5 ? '✅' : '❌'}`).join('\n')}`;

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Box textAlign="center" p={2}>
                <Typography variant="h4" color={isWin ? 'success.main' : 'error.main'}>
                    {isWin ? 'Победа!' : 'Поражение!'}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    {description}
                </Typography>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    <Typography variant="h6">{emoji_first} {first}</Typography>
                    <Typography variant="body1" mx={2}>vs.</Typography>
                    <Typography variant="h6">{second} {emoji_second}</Typography>
                </Box>
                {isWin && (
                    <>
                        {guessed_count === 0 ? (
                            <Typography variant="h6" color="primary" gutterBottom>
                                Поздравляем! Вы первый, кто это отгадал! 🎉
                            </Typography>
                        ) : (
                            <Typography variant="body2" gutterBottom>
                                Отгадали правильно {guessed_count} других {guessed_count === 1 ? 'человек' : 'людей'}.
                            </Typography>
                        )}
                    </>
                )}
                {!isWin && (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Вы выиграли {historyLength - 1} битв!
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={() => window.open(`https://t.me/share/url?url=${encodeURIComponent(shareText)}`, '_blank')}
                        >
                            Поделиться в Telegram
                        </Button>
                    </>
                )}
                {isWin ? (
                    <Box component="form" onSubmit={handleNextSubmit} sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label={`Как победить "${first}"?`}
                            variant="outlined"
                            value={nextWord}
                            onChange={(e) => setNextWord(e.target.value)}
                            required
                        />
                        {error && <Typography color="error">{error}</Typography>}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ mt: 2 }}
                            disabled={loading}
                        >
                            Следующая битва!
                        </Button>
                    </Box>
                ) : (
                    <Button variant="contained" color="primary" onClick={onRestart} sx={{ mt: 2 }}>
                        Играть снова
                    </Button>
                )}
            </Box>
        </motion.div>
    );
};

export default BattleResult;
