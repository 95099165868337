import React from 'react';
import { IBattleResult } from '../utils/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

interface HistoryProps {
    history: IBattleResult[];
}

const History: React.FC<HistoryProps> = ({ history }) => (
    <Box mt={2} mb={2} component={Paper} elevation={3} p={2} sx={{ maxHeight: '180px', overflowY: 'auto' }}>
        <Typography variant="h6" gutterBottom>
            История битв
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {history.map((result, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto 1fr auto',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body1">{result.emoji_first}</Typography>
                        <Typography variant="body1" noWrap>{result.first}</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>vs</Typography>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body1">{result.emoji_second}</Typography>
                        <Typography variant="body1" noWrap>{result.second}</Typography>
                    </Box>
                    <Typography variant="body2" sx={{ textAlign: 'center' }}>
                        {result.probability > 0.5 ? '✅' : '❌'}
                    </Typography>
                </Box>
            ))}
        </Box>
    </Box>
);

export default History;
